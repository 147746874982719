// @flow
import type { IconName } from 'hooks/usePreferences/allTools';
import customIcons from 'hooks/usePreferences/customIcons';
import textSizes from 'styles/sizes';
import {
  Adjust,
  Block,
  Bookmark,
  BorderColor,
  BorderHorizontal,
  BorderVertical,
  Cached,
  CallMade,
  Check,
  CheckCircle,
  Code,
  CropSquare,
  Clear,
  Delete,
  Error,
  ErrorOutline,
  Exposure,
  FiberManualRecord,
  FindInPage,
  FontDownload,
  GpsNotFixed,
  HelpOutlineTwoTone,
  InvertColors,
  Layers,
  Navigation,
  PanoramaFishEye,
  PanTool,
  PlayArrow,
  PriorityHigh,
  Refresh,
  Save,
  Share,
  SkipNext,
  SkipPrevious,
  Straighten,
  SwapCalls,
  SwapHoriz,
  SwapVert,
  ThreeDRotation,
  VerticalSplit,
  Visibility,
  Warning,
  ZoomIn,
} from '@material-ui/icons';

const sizes = {
  small: '1.6rem',
  large: '2.4rem',
};

export type IconProps = $ReadOnly<{
  size?: 'small' | 'large' | $Keys<typeof textSizes>,
  name: IconName,
  className?: string,
  css?: string,
  svgIcon?: string,
  'data-testid'?: string,
}>;

const getSize = ({ name, size }: { name: void | IconName, size: string }) => {
  if (size in sizes) {
    // Manually fix sizes of MUI icons
    switch (name) {
      case 'erase':
      case 'pan_tool':
      case 'reset':
      case 'regular_scroll':
        return size === 'large' ? '2rem' : '1.4rem';
      default:
        return sizes[size];
    }
  }

  return textSizes[size];
};

const resolveIcon = ({
  name,
  css,
  ...props
}: {
  className: void | string,
  css?: string,
  name: IconName,
  'data-testid'?: string,
  style: { fontSize: string },
}) => {
  switch (name) {
    case 'pan_tool':
      return <PanTool {...props} css={css} />;
    case 'zoom_in':
      return <ZoomIn {...props} css={css} />;
    case 'swap_horizontal':
      return <SwapHoriz {...props} css={css} />;
    case 'swap_vertical':
      return <SwapVert {...props} css={css} />;
    case 'exposure':
      return <Exposure {...props} css={css} />;
    case 'straighten':
      return <Straighten {...props} css={css} />;
    case 'share':
      return <Share {...props} css={css} />;
    case 'code':
      return <Code {...props} css={css} />;
    case 'panorama_fish_eye':
      return <PanoramaFishEye {...props} css={css} />;
    case 'adjust':
      return <Adjust {...props} css={css} />;
    case 'invert_colors':
      return <InvertColors {...props} css={css} />;
    case 'cached':
      return <Cached {...props} css={css} />;
    case 'find_in_page':
      return <FindInPage {...props} css={css} />;
    case 'crop_square':
      return <CropSquare {...props} css={css} />;
    case 'play_arrow':
      return <PlayArrow {...props} css={css} />;
    case 'delete':
      return <Delete {...props} css={css} />;
    case 'vertical_split':
      return <VerticalSplit {...props} css={css} />;
    case 'location_searching':
      return <GpsNotFixed {...props} css={css} />;
    case 'key_image':
      return <Bookmark {...props} css={css} />;
    case 'layers':
      return <Layers {...props} css={css} />;
    case 'border_color':
      return <BorderColor {...props} css={css} />;
    case 'border_vertical':
      return <BorderVertical {...props} css={css} />;
    case 'border_horizontal':
      return <BorderHorizontal {...props} css={css} />;
    case 'font_download':
      return <FontDownload {...props} css={css} />;
    case '3d_rotation':
      return <ThreeDRotation {...props} css={css} />;
    case 'anatomic_navigator':
      return <Navigation {...props} css={css} />;
    case 'check_circle':
      return <CheckCircle {...props} css={css} />;
    case 'delete_icon':
      return <Delete {...props} css={css} />;
    case 'fiber_manual_record':
      return <FiberManualRecord {...props} css={css} />;
    case 'save':
      return <Save {...props} css={css} />;
    case 'skip_previous':
      return <SkipPrevious {...props} css={css} />;
    case 'skip_next':
      return <SkipNext {...props} css={css} />;
    case 'arrow':
      return <CallMade {...props} css={css} />;
    case 'visibility':
      return <Visibility {...props} css={css} />;
    case 'swap_calls':
      return <SwapCalls {...props} css={css} />;
    case 'check':
      return <Check {...props} css={css} />;
    case 'refresh':
      return <Refresh {...props} css={css} />;
    case 'warning':
      return <Warning {...props} css={css} />;
    case 'block':
      return <Block {...props} css={css} />;
    case 'clear':
      return <Clear {...props} css={css} />;
    case 'error':
      return <Error {...props} css={css} />;
    case 'error_outline':
      return <ErrorOutline {...props} css={css} />;
    case 'priority_high':
      return <PriorityHigh {...props} css={css} />;
    default:
      return <HelpOutlineTwoTone {...props} css={css} />;
  }
};

export const Icon = ({
  size = 'large',
  className,
  name,
  svgIcon,
  'data-testid': testid,
  ...rest
}: IconProps): React$Node => {
  const computedSize = getSize({ name, size });

  if (svgIcon != null && svgIcon.length > 0) {
    return (
      <div
        className={className}
        css={`
          // svgIcon comes from 3rd-parties and may not be 100% safe to inline it
          // we instead use it as base64 image so that it can be safely used in
          // the application without XSS risks
          mask-image: url(${svgIcon});
          width: ${computedSize};
          aspect-ratio: 1;
          background-color: currentColor;
        `}
      />
    );
  }

  if (customIcons.hasOwnProperty(name)) {
    // $FlowIgnore[incompatible-type] checked above
    const { svg, stroke, fontSize } = customIcons[name];
    return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        css={`
          font-size: ${fontSize ?? computedSize};
          stroke: ${stroke};
          width: ${computedSize};
          height: ${computedSize};
          fill: transparent;
          strokewidth: 2;
        `}
      >
        {svg}
      </svg>
    );
  }

  return resolveIcon({
    name,
    className,
    style: {
      fontSize: computedSize,
    },
    'data-testid': testid ?? `icon-${name}`,
    ...rest,
  });
};
